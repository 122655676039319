import {Role} from "~/types/interfaces/entitites/RoleInterface";
import {BillingAddress} from "~/types/interfaces/entitites/BillingAddressInterface";
import {PaymentMethod} from "~/types/interfaces/entitites/PaymentMethodInterface";
import {Document} from "~/types/interfaces/entitites/DocumentInterface";
import {Plan} from "~/types/interfaces/entitites/PlanInterface";
import {Order} from "~/types/interfaces/entitites/OrderInterface";
import {Gender} from "~/types/interfaces/entitites/GenderInterface";
import {Study} from "~/types/interfaces/entitites/StudyInterface";
import {Media} from "~/types/interfaces/entitites/MediaInterface";
import {Bank} from "~/types/interfaces/entitites/BankInterface";
import {Product} from "~/types/interfaces/entitites/ProductInterface";
import {Subscription} from "~/types/interfaces/entitites/SubscriptionInterface";
import {array} from "yup";
import {Ticket} from "~/types/interfaces/entitites/TicketInterface";
import {TypeOfDisable} from "~/types/interfaces/entitites/TypeOfDisableInterface";

export interface User {
  name: string;
  surname: string;
  full_name: string;
  code: string;
  email: string;
  mobile_phone: string;
  landline: string;
  notes: string;
  nif: string;
  hash: string;
  active: boolean;
  has_first_login: boolean;
  email_verified: string;
  status: keyof typeof USER_STATUS_AVAILABLE;
  documents_limit: number;
  documents_limit_on_register: number;
  birthday: string;
  lead_source: string;
  has_disable_requested: boolean;
  has_simpatizante_requested: boolean;

  parent: User;
  children: User[];
  role: Role;
  billingAddresses: BillingAddress[];
  paymentMethods: PaymentMethod[];
  documents: Document[];
  currentPlan: Plan;
  medias: Media[];
  gender: Gender;
  type_of_disable: TypeOfDisable;
  study: Study;
  banks: Bank[];
  products: Product[];
  currentSubscription: Subscription;
  subscriptions: Subscription[];
  tickets: Ticket[];
  latest_tickets: Ticket[];

  has_tickets: boolean;
  has_open_tickets: boolean;
  has_claims: boolean;
  has_open_claims: boolean;

  orders: Order[];
  logs: UserLog[];
  api_token: string;
}

export interface UserLog {
  user: User;
  description: string;
  created_at: string;
}

export interface UserCreateRequest {
  email: string;
  name: string;
  surname: string;
  password: string;
  password_confirmation: string;
  role_hash: string;
}
export interface UserEditRequest {
  name: string;
  surname: string;
  nif: string;
  email: string;
  mobile_phone: string;
  landline: string;
  study_hash: string;
  gender_hash: string;
  medias: string;
  banks: string;
  products: string;
  birthday: string;
  lead_source: string;
  password: string|undefined|null;
  password_confirmation: string|undefined|null;
}

export interface AdminCreateRequest {
  email: string;
  name: string;
  surname: string;
  password: string;
  password_confirmation: string;
  role_hash: string;
}

export interface AdminEditRequest {
  email: string;
  name: string;
  surname: string;
  password: string|undefined|null;
  password_confirmation: string|undefined|null;
  role_hash: string;
}

export enum USER_STATUS_AVAILABLE {
  "draft" = "draft",
  "pending" = "pending",
  "validated" = "validated",
}

export enum USER_STATUS_TRANS {
  "draft" = "Borrador",
  "pending" = "Pendiente",
  "validated" = "Validado",
}

export enum USER_IMPORT_FILE_HEADERS {
  "email" = "email",
  "nombre" = "nombre",
  "apellidos" = "apellidos",
  "dni" = "dni",
  "plan" = "plan",
  "medios" = "medios",
  "productos" = "productos",
  "bancos" = "bancos",
  "telefono_fijo" = "telefono_fijo",
  "telefono_movil" = "telefono_movil",
  "direccion" = "direccion",
  "poblacion" = "poblacion",
  "codigo_postal" = "codigo_postal",
  "genero" = "genero",
  "empresa" = "empresa",
  // "iban" = "iban",
}

export interface UserImport {
  email: string;
  nombre: string;
  apellidos: string;
  dni: string;
  plan: string;
  medios: array;
  productos: array;
  bancos: array;
  telefono_fijo: string;
  telefono_movil: string;
  direccion: string;
  problacion: string;
  genero: string;
  empresa: string;
  cp: string;
  iban: string;
  externo: string;
}

export enum USER_LEAD_SOURCE_AVAILABLE {
  "E-Mail" = "E-Mail",
  "Newsletter" = "Newsletter",
  "TV" = "TV",
  "Radio" = "Radio",
  "Redes Sociales" = "Redes Sociales",
  "Webinar" = "Webinar",
  "Google" = "Google",
  "Web" = "Web",
  "Recomendacion" = "Recomendación",
  "Otro" = "Otro",
}

export interface UserAssociableRequest {
  name: string;
  surname: string;
  nif: string;
  email: string;
  file1: File;
  file2: File;
  file3: File;
}

import {ref} from 'vue';
import {defineStore} from 'pinia';
import {User, UserEditRequest} from '~/types/interfaces/entitites/UserInterface';
import {
    ForgotPasswordRequest,
    LoginRequest,
    RecoverPasswordRequest,
    RegisterRequest
} from '~/types/interfaces/auth/AuthInterface';
import {ErrorInterface} from "~/types/interfaces/ErrorInterface";
import {useCSRF} from "~/stores/csrf";
import {useAPI} from "~/composables/useAPI";
import {Plan} from "~/types/interfaces/entitites/PlanInterface";
import {ModifySubscriptionRequest} from "~/types/interfaces/entitites/SubscriptionInterface";
import {SuccessInterface} from "~/types/interfaces/SuccessInterface";
import {useToken} from "~/stores/token";

export const useAuthStore = defineStore('auth', () => {
    const returnUrl = ref<string | null>(null);
    const error = ref<ErrorInterface | null>(null);
    const succes = ref<SuccessInterface | null>(null);
    const user = ref<User>({} as User);
    const isAuthenticated = ref(false);
    const csrfStore = useCSRF();
    const useApi = useAPI();
    const tokenStore = useToken();

    // computed()s become getters
    const currentUser = computed(() => user.value)
    const currentReturnUrl = computed(() => returnUrl.value)

    function setAuth(authUser: User) {
        isAuthenticated.value = true;
        user.value = authUser;
        error.value = null;
    }

    function setReturnUrl(url: string) {
        returnUrl.value = url;
    }

    function setError(errorData: any) {
        error.value = errorData;
    }

    function setSuccess(successData: any) {
        succes.value = successData;
    }

    function resetErrors() {
        error.value = null;
    }

    function purgeAuth() {
        isAuthenticated.value = false;
        user.value = {} as User;
        error.value = null;
    }

    async function login(credentials: LoginRequest) {
        resetErrors();

        // Si queremos funcionar con la session de Laravel.
        // if (csrfStore.token === null) {
        //     await csrfStore.csrf();
        // }

        await useApi.apiFetch('/auth/login', {
            method: 'POST',
            body: JSON.stringify(credentials),
        }).then(({data}) => {
            // Si queremos funcionar con la session de Laravel.
            // El login nos devolvería el usuario.
            if (csrfStore.token && data) {
                setAuth(data);
            }
            if (tokenStore.currentToken === null) {
                tokenStore.setToken(data.token);
                setAuth({} as User);
            }
        }).catch(({response}) => {
            if (response) {
                setError(response._data);
            } else {
                setError({
                        application_code: '',
                        data: {},
                        message: 'No hay conexión',
                        status: 'Error',
                    }
                );
            }
        });
    }

    async function refreshUser() {
        await getUser(true);
    }

    async function getUser(refresh = false) {
        if (!refresh) {
            if (isAuthenticated.value && user.value) {
                return user.value
            }
        }

        const response = await useApi.apiFetch('/client/profile/me')
            .catch(({response}) => {
                purgeAuth();
                if (response) {
                    setError(response._data);
                }
            });
        if (response) {
            setAuth(response.data);
        }

        return user.value
    }

    async function update(data: UserEditRequest) {
        await useApi.apiFetch(`/client/profile/update`, {
            method: 'PUT',
            body: JSON.stringify(data),
        }).then(({data}) => {
            try {
                setAuth(data);
            } catch (e) {
                console.log("exc", e);
            }
        }).catch(({response}) => {
            if (response) {
                setError(response._data);
            } else {
                setError({
                        application_code: '',
                        data: {},
                        message: 'No hay conexión',
                        status: 'Error',
                    }
                );
            }
        });
    }

    async function logout() {
        resetErrors();
        if (isAuthenticated.value && user.value) {
            await useApi.apiFetch('/auth/logout', {
                method: 'POST',
            }).then(({data}) => {
                if (csrfStore.token) {
                    csrfStore.clearToken()
                }
                if (tokenStore.currentToken) {
                    tokenStore.clearToken()
                }
                purgeAuth();
            }).catch(({response}) => {
                if (response) {
                    setError(response._data);
                } else {
                    setError({
                            application_code: '',
                            data: {},
                            message: 'No hay conexión',
                            status: 'Error',
                        }
                    );
                }
            });
        }
    }

    /**
     * Esta función es sólo para la parte cliente. La ponemos aquí para tenerlo desarrollado.
     * @param credentials
     */
    async function register(credentials: RegisterRequest) {
        // Para funcionar con un Token como tal.
        // if (csrfStore.token === null) {
        //     await csrfStore.csrf();
        // }

        await useApi.apiFetch('/client/register/initial-register', {
            method: 'POST',
            body: JSON.stringify(credentials),
        }).then(({data}) => {
            // Si queremos funcionar con la session de Laravel.
            // El login nos devolvería el usuario.
            if (csrfStore.token && data) {
                setAuth(data);
            }
            if (tokenStore.currentToken === null) {
                tokenStore.setToken(data.token);
                setAuth({} as User);
            }
        }).catch(({response}) => {
            if (response) {
                setError(response._data);
            } else {
                setError({
                        application_code: '',
                        data: {},
                        message: 'No hay conexión',
                        status: 'Error',
                    }
                );
            }
        });
    }

    async function finishRegister(plan: Plan) {
        // Para funcionar con un Token como tal.
        // if (csrfStore.token === null) {
        //     await csrfStore.csrf();
        // }

        await useApi.apiFetch('/client/register/finish-register', {
            method: 'POST',
            body: JSON.stringify({
                'plan_hash': plan.hash
            }),
        }).then(({data}) => {

        }).catch(({response}) => {
            if (response) {
                setError(response._data);
            } else {
                setError({
                        application_code: '',
                        data: {},
                        message: 'No hay conexión',
                        status: 'Error',
                    }
                );
            }
        });
    }

    async function modifySubscription(data: ModifySubscriptionRequest) {
        await useApi.apiFetch(`/client/profile/modify-subscription`, {
            method: 'PUT',
            body: JSON.stringify(data),
        }).then(({data}) => {
            try {
                setAuth(data);
                setSuccess(data);
            } catch (e) {
                console.log("exc", e);
            }
        }).catch(({response}) => {
            if (response) {
                setError(response._data);
            } else {
                setError({
                        application_code: '',
                        data: {},
                        message: 'No hay conexión',
                        status: 'Error',
                    }
                );
            }
        });
    }

    async function forgotPassword(credentials: ForgotPasswordRequest) {
        // Para funcionar con un Token como tal.
        // if (csrfStore.token === null) {
        //     await csrfStore.csrf();
        // }

        await useApi.apiFetch('/auth/forgot-password', {
            method: 'POST',
            body: JSON.stringify(credentials),
        }).then(({data}) => {
            // Para funcionar con un Token como tal.
            if (csrfStore.token && data) {
                csrfStore.setToken(data.token)
            }
            setAuth(data);
        }).catch(({response}) => {
            if (response) {
                setError(response._data);
            } else {
                setError({
                        application_code: '',
                        data: {},
                        message: 'No hay conexión',
                        status: 'Error',
                    }
                );
            }
        });
    }

    async function recoverPassword(credentials: RecoverPasswordRequest) {
        // Para funcionar con un Token como tal.
        // if (csrfStore.token === null) {
        //     await csrfStore.csrf();
        // }

        await useApi.apiFetch('/auth/recover-password', {
            method: 'POST',
            body: JSON.stringify(credentials),
        }).then(({data}) => {
            // Para funcionar con un Token como tal.
            if (csrfStore.token && data) {
                csrfStore.setToken(data.token)
            }
            setAuth(data);
        }).catch(({response}) => {
            if (response) {
                setError(response._data);
            } else {
                setError({
                        application_code: '',
                        data: {},
                        message: 'No hay conexión',
                        status: 'Error',
                    }
                );
            }
        });
    }

    return {
        error,
        user,
        isAuthenticated,
        currentUser,
        currentReturnUrl,
        resetErrors,
        login,
        update,
        logout,
        refreshUser,
        getUser,
        register,
        finishRegister,
        modifySubscription,
        forgotPassword,
        recoverPassword,
        setReturnUrl
    };
});

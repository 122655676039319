import { default as email_45verifiedIl9mDjtBwoMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/email-verified.vue?macro=true";
import { default as completeL1I9ODbML9Meta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/finish-sign-up/complete.vue?macro=true";
import { default as select_45planKDfTC3UxOpMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/finish-sign-up/select-plan.vue?macro=true";
import { default as forgot_45passwordrkITc6vHSfMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/forgot-password.vue?macro=true";
import { default as not_45allowedtk7UF1qVO7Meta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/not-allowed.vue?macro=true";
import { default as not_45foundG9x7QdRcf0Meta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/not-found.vue?macro=true";
import { default as paypal_45payment_45savedGiaLhUsCyPMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/paypal-payment-saved.vue?macro=true";
import { default as recover_45passwordor2rOEsYIzMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/recover-password.vue?macro=true";
import { default as sign_45inb1JDOrLSgxMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/sign-in.vue?macro=true";
import { default as sign_45upR3zJ9idWuWMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/sign-up.vue?macro=true";
import { default as verify_45emailafTeo2BlsTMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/verify-email.vue?macro=true";
import { default as landingLTkwhOjm2XMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/claims/landing.vue?macro=true";
import { default as dashboardI5dreiwD1BMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/dashboard.vue?macro=true";
import { default as listO9ilq1FZecMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/faqs/list.vue?macro=true";
import { default as indexhChWw2uiYJMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/index.vue?macro=true";
import { default as download_45_91hash_93s67xi5qjPFMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/judicial-precedents/download-[hash].vue?macro=true";
import { default as listEeq0ekeTqiMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/judicial-precedents/list.vue?macro=true";
import { default as listsSv7aSeDB4Meta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/news/list.vue?macro=true";
import { default as profileVxjHwpxlZdMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/profile.vue?macro=true";
import { default as download_45_91hash_93K4GUBVdhg6Meta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/public-documents/download-[hash].vue?macro=true";
import { default as listYFnT9GRqO0Meta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/public-documents/list.vue?macro=true";
import { default as listxjFMnb6OloMeta } from "/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/tickets/list.vue?macro=true";
export default [
  {
    name: email_45verifiedIl9mDjtBwoMeta?.name ?? "auth-email-verified",
    path: email_45verifiedIl9mDjtBwoMeta?.path ?? "/auth/email-verified",
    meta: email_45verifiedIl9mDjtBwoMeta || {},
    alias: email_45verifiedIl9mDjtBwoMeta?.alias || [],
    redirect: email_45verifiedIl9mDjtBwoMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/email-verified.vue").then(m => m.default || m)
  },
  {
    name: completeL1I9ODbML9Meta?.name ?? "auth-finish-sign-up-complete",
    path: completeL1I9ODbML9Meta?.path ?? "/auth/finish-sign-up/complete",
    meta: completeL1I9ODbML9Meta || {},
    alias: completeL1I9ODbML9Meta?.alias || [],
    redirect: completeL1I9ODbML9Meta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/finish-sign-up/complete.vue").then(m => m.default || m)
  },
  {
    name: select_45planKDfTC3UxOpMeta?.name ?? "auth-finish-sign-up-select-plan",
    path: select_45planKDfTC3UxOpMeta?.path ?? "/auth/finish-sign-up/select-plan",
    meta: select_45planKDfTC3UxOpMeta || {},
    alias: select_45planKDfTC3UxOpMeta?.alias || [],
    redirect: select_45planKDfTC3UxOpMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/finish-sign-up/select-plan.vue").then(m => m.default || m)
  },
  {
    name: forgot_45passwordrkITc6vHSfMeta?.name ?? "auth-forgot-password",
    path: forgot_45passwordrkITc6vHSfMeta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordrkITc6vHSfMeta || {},
    alias: forgot_45passwordrkITc6vHSfMeta?.alias || [],
    redirect: forgot_45passwordrkITc6vHSfMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: not_45allowedtk7UF1qVO7Meta?.name ?? "auth-not-allowed",
    path: not_45allowedtk7UF1qVO7Meta?.path ?? "/auth/not-allowed",
    meta: not_45allowedtk7UF1qVO7Meta || {},
    alias: not_45allowedtk7UF1qVO7Meta?.alias || [],
    redirect: not_45allowedtk7UF1qVO7Meta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/not-allowed.vue").then(m => m.default || m)
  },
  {
    name: not_45foundG9x7QdRcf0Meta?.name ?? "auth-not-found",
    path: not_45foundG9x7QdRcf0Meta?.path ?? "/auth/not-found",
    meta: not_45foundG9x7QdRcf0Meta || {},
    alias: not_45foundG9x7QdRcf0Meta?.alias || [],
    redirect: not_45foundG9x7QdRcf0Meta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/not-found.vue").then(m => m.default || m)
  },
  {
    name: paypal_45payment_45savedGiaLhUsCyPMeta?.name ?? "auth-paypal-payment-saved",
    path: paypal_45payment_45savedGiaLhUsCyPMeta?.path ?? "/auth/paypal-payment-saved",
    meta: paypal_45payment_45savedGiaLhUsCyPMeta || {},
    alias: paypal_45payment_45savedGiaLhUsCyPMeta?.alias || [],
    redirect: paypal_45payment_45savedGiaLhUsCyPMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/paypal-payment-saved.vue").then(m => m.default || m)
  },
  {
    name: recover_45passwordor2rOEsYIzMeta?.name ?? "auth-recover-password",
    path: recover_45passwordor2rOEsYIzMeta?.path ?? "/auth/recover-password",
    meta: recover_45passwordor2rOEsYIzMeta || {},
    alias: recover_45passwordor2rOEsYIzMeta?.alias || [],
    redirect: recover_45passwordor2rOEsYIzMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/recover-password.vue").then(m => m.default || m)
  },
  {
    name: sign_45inb1JDOrLSgxMeta?.name ?? "auth-sign-in",
    path: sign_45inb1JDOrLSgxMeta?.path ?? "/auth/sign-in",
    meta: sign_45inb1JDOrLSgxMeta || {},
    alias: sign_45inb1JDOrLSgxMeta?.alias || [],
    redirect: sign_45inb1JDOrLSgxMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/sign-in.vue").then(m => m.default || m)
  },
  {
    name: sign_45upR3zJ9idWuWMeta?.name ?? "auth-sign-up",
    path: sign_45upR3zJ9idWuWMeta?.path ?? "/auth/sign-up",
    meta: sign_45upR3zJ9idWuWMeta || {},
    alias: sign_45upR3zJ9idWuWMeta?.alias || [],
    redirect: sign_45upR3zJ9idWuWMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/sign-up.vue").then(m => m.default || m)
  },
  {
    name: verify_45emailafTeo2BlsTMeta?.name ?? "auth-verify-email",
    path: verify_45emailafTeo2BlsTMeta?.path ?? "/auth/verify-email",
    meta: verify_45emailafTeo2BlsTMeta || {},
    alias: verify_45emailafTeo2BlsTMeta?.alias || [],
    redirect: verify_45emailafTeo2BlsTMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: landingLTkwhOjm2XMeta?.name ?? "claims-landing",
    path: landingLTkwhOjm2XMeta?.path ?? "/claims/landing",
    meta: landingLTkwhOjm2XMeta || {},
    alias: landingLTkwhOjm2XMeta?.alias || [],
    redirect: landingLTkwhOjm2XMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/claims/landing.vue").then(m => m.default || m)
  },
  {
    name: dashboardI5dreiwD1BMeta?.name ?? "dashboard",
    path: dashboardI5dreiwD1BMeta?.path ?? "/dashboard",
    meta: dashboardI5dreiwD1BMeta || {},
    alias: dashboardI5dreiwD1BMeta?.alias || [],
    redirect: dashboardI5dreiwD1BMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: listO9ilq1FZecMeta?.name ?? "faqs-list",
    path: listO9ilq1FZecMeta?.path ?? "/faqs/list",
    meta: listO9ilq1FZecMeta || {},
    alias: listO9ilq1FZecMeta?.alias || [],
    redirect: listO9ilq1FZecMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/faqs/list.vue").then(m => m.default || m)
  },
  {
    name: indexhChWw2uiYJMeta?.name ?? "index",
    path: indexhChWw2uiYJMeta?.path ?? "/",
    meta: indexhChWw2uiYJMeta || {},
    alias: indexhChWw2uiYJMeta?.alias || [],
    redirect: indexhChWw2uiYJMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/index.vue").then(m => m.default || m)
  },
  {
    name: download_45_91hash_93s67xi5qjPFMeta?.name ?? "judicial-precedents-download-hash",
    path: download_45_91hash_93s67xi5qjPFMeta?.path ?? "/judicial-precedents/download-:hash()",
    meta: download_45_91hash_93s67xi5qjPFMeta || {},
    alias: download_45_91hash_93s67xi5qjPFMeta?.alias || [],
    redirect: download_45_91hash_93s67xi5qjPFMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/judicial-precedents/download-[hash].vue").then(m => m.default || m)
  },
  {
    name: listEeq0ekeTqiMeta?.name ?? "judicial-precedents-list",
    path: listEeq0ekeTqiMeta?.path ?? "/judicial-precedents/list",
    meta: listEeq0ekeTqiMeta || {},
    alias: listEeq0ekeTqiMeta?.alias || [],
    redirect: listEeq0ekeTqiMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/judicial-precedents/list.vue").then(m => m.default || m)
  },
  {
    name: listsSv7aSeDB4Meta?.name ?? "news-list",
    path: listsSv7aSeDB4Meta?.path ?? "/news/list",
    meta: listsSv7aSeDB4Meta || {},
    alias: listsSv7aSeDB4Meta?.alias || [],
    redirect: listsSv7aSeDB4Meta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/news/list.vue").then(m => m.default || m)
  },
  {
    name: profileVxjHwpxlZdMeta?.name ?? "profile",
    path: profileVxjHwpxlZdMeta?.path ?? "/profile",
    meta: profileVxjHwpxlZdMeta || {},
    alias: profileVxjHwpxlZdMeta?.alias || [],
    redirect: profileVxjHwpxlZdMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: download_45_91hash_93K4GUBVdhg6Meta?.name ?? "public-documents-download-hash",
    path: download_45_91hash_93K4GUBVdhg6Meta?.path ?? "/public-documents/download-:hash()",
    meta: download_45_91hash_93K4GUBVdhg6Meta || {},
    alias: download_45_91hash_93K4GUBVdhg6Meta?.alias || [],
    redirect: download_45_91hash_93K4GUBVdhg6Meta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/public-documents/download-[hash].vue").then(m => m.default || m)
  },
  {
    name: listYFnT9GRqO0Meta?.name ?? "public-documents-list",
    path: listYFnT9GRqO0Meta?.path ?? "/public-documents/list",
    meta: listYFnT9GRqO0Meta || {},
    alias: listYFnT9GRqO0Meta?.alias || [],
    redirect: listYFnT9GRqO0Meta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/public-documents/list.vue").then(m => m.default || m)
  },
  {
    name: listxjFMnb6OloMeta?.name ?? "tickets-list",
    path: listxjFMnb6OloMeta?.path ?? "/tickets/list",
    meta: listxjFMnb6OloMeta || {},
    alias: listxjFMnb6OloMeta?.alias || [],
    redirect: listxjFMnb6OloMeta?.redirect || undefined,
    component: () => import("/home/gerard/Work/vawarelabs/asufin/asufin-panel-v2/pages/tickets/list.vue").then(m => m.default || m)
  }
]
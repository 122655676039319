import Swal from "sweetalert2";
import {Permission} from "~/types/interfaces/entitites/RoleInterface";

export function useUtil() {
    const router = useRouter();
    const route = useRoute();

    const showLoading = () => {
        Swal.fire({
            title: 'Cargando',
            text: 'Estamos cargando los datos, por favor, espera.',
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Entendido",
            heightAuto: false,
            customClass: {
                confirmButton: "btn fw-semibold btn-primary",
            },
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    };


    const closeLoading = () => {
        Swal.hideLoading();
        Swal.close();
    };
    const wip = () => {
        Swal.fire({
            title: 'WIP',
            text: 'Esta funcionalidad estará disponible cuando la versión BETA esté finalizada.',
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Entendido",
            heightAuto: false,
            customClass: {
                confirmButton: "btn fw-semibold btn-primary",
            },
        });
    };

    const defaultSaveSuccess = () => {
        Swal.fire({
            title: 'Datos guardados',
            text: 'Se han actualizado los datos correctamente',
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Entendido",
            heightAuto: false,
            customClass: {
                confirmButton: "btn fw-semibold btn-primary",
            },
        });
    };

    const defaultCopySuccess = () => {
        Swal.fire({
            title: 'Copiado con éxito',
            text: 'Se ha copiado al portapapeles',
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Entendido",
            heightAuto: false,
            customClass: {
                confirmButton: "btn fw-semibold btn-primary",
            },
        });
    };

    const defaultRemoveSuccess = () => {
        Swal.fire({
            title: 'Datos eliminados',
            text: 'Se han borrado los datos correctamente',
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Entendido",
            heightAuto: false,
            customClass: {
                confirmButton: "btn fw-semibold btn-primary",
            },
        });
    };


    const defaultDownloadSuccess = () => {
        Swal.fire({
            title: 'Archivo descargado',
            text: 'Se ha descargado el archivo correctamente',
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Entendido",
            heightAuto: false,
            customClass: {
                confirmButton: "btn fw-semibold btn-primary",
            },
        });
    };

    const defaultError = (title: string, message: string) => {
        Swal.fire({
            title: title,
            text: message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Entendido",
            heightAuto: false,
            customClass: {
                confirmButton: "btn fw-semibold btn-light-danger",
            },
        });
    };

    const showInfo = (title: string, message: string) => {
        Swal.fire({
            title: title,
            text: message,
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Entendido",
            heightAuto: false,
            customClass: {
                confirmButton: "btn fw-semibold btn-primary",
            },
        });
    };

    const showGoToLogin = () => {
        Swal.fire({
            title: 'Ya dispones de una cuenta creada',
            text: 'Por favor utiliza tu usuario y contraseña para entrar en tu cuenta. Si no recuerdas el email con el que te registraste, contacta con nosotros en info@asufin.com',
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Entendido",
            denyButtonText: "Iniciar sesión",
            heightAuto: false,
            showDenyButton: true,
            customClass: {
                confirmButton: "btn fw-semibold btn-light-dark",
                denyButton: "btn fw-semibold btn-primary",
            },
        }).then((result) => {
            if (result.isDenied) {
                router.push('/iniciar-sesion');
            }
        });
    };

    const hasPermission = (permissions: Permission[], type: string, grant: string) => {
        return permissions.find((permission, index) => {
            return permission.type === type && permission.grant === grant;
        }) !== undefined;
    };

    const dmYFormat = (date: Date) => {
        if (date) {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        }

        return '';
    }

    const isPastDate = (date: Date|string) => {
        let dateObj: Date;

        if (typeof date === 'string') {
            dateObj = createDateFromdmYFormat(date);
        } else {
            dateObj = date;
        }

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return dateObj <= today;
    }

    const createDateFromdmYFormat = (dateString: string) => {
        const [datePart] = dateString.split(' ');
        const [day, month, year] = datePart.split('/').map(Number);
        return new Date(year, month - 1, day);
    }

    const randomString = (length: number) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    const searchString = (search: string, word: string) => {
        return word.toLowerCase().includes(search.toLowerCase());
    }

    const searchInArrayObject = (search: string, array: [], objectKey: string, multiSelectFormat: boolean = true) => {

        const newArray = [];

        array.find((object) => {
            if (searchString(search, object[objectKey])) {
                if (multiSelectFormat) {
                    newArray.push({value: object.hash, label: object.name, name: object.name });
                } else {
                    newArray.push(object);
                }
            }
        })

        return newArray;
    }

    const truncateString = (string: string, length: number) => {
        if (string.length > length) {
            return string.substring(0, length) + "...";
        }
        return string;
    };

    const calculateElapsedMinutes = (startTime: Date, currentTime: Date) => {
        const elapsedTime = (currentTime - startTime) / 1000; // Diferencia en segundos

        // Convertir a minutos y redondear hacia abajo
        return Math.floor(elapsedTime / 60);
    };

    const checkMetricQueryParams = () => {
        const params = route.query;
        const requiredParams = ['utm_medium', '_hsenc', '_hsmi', 'utm_content', 'utm_source'];

        return requiredParams.some(param => params[param] !== undefined);
    };

    const getMetricQueryParams = () => {
        const params = route.query;
        let queryString = '';

        const allowedParams = ['utm_medium', '_hsenc', '_hsmi', 'utm_content', 'utm_source'];

        Object.keys(params).forEach(key => {
            if (allowedParams.includes(key)) {
                queryString += `${key}=${params[key]}&`;
            }
        });

        if (queryString.length > 0) {
            queryString = queryString.slice(0, -1);
        }

        return queryString;
    };

    const generateLink = (to: string) => {
        if (checkMetricQueryParams()) {
            const queryParams = getMetricQueryParams();
            const hasExistingParams = to.includes('?');

            return `${to}${hasExistingParams ? '&' : '?'}${queryParams}`;
        } else {
            return to;
        }
    };

    return {
        showLoading,
        closeLoading,
        wip,
        defaultSaveSuccess,
        defaultCopySuccess,
        defaultRemoveSuccess,
        defaultDownloadSuccess,
        defaultError,
        showInfo,
        showGoToLogin,
        hasPermission,
        dmYFormat,
        randomString,
        searchString,
        searchInArrayObject,
        truncateString,
        calculateElapsedMinutes,
        checkMetricQueryParams,
        getMetricQueryParams,
        generateLink,
        isPastDate,
        createDateFromdmYFormat
    };
}

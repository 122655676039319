import type { ModuleOptions } from '#cookie-control/types'

export default {
  "barPosition": "bottom-full",
  "closeModalOnClickOutside": false,
  "colors": {
    "barBackground": "#fff",
    "barButtonBackground": "#f9f9f9",
    "barButtonColor": "#000",
    "barButtonHoverBackground": "#eb2b8e",
    "barButtonHoverColor": "#fff",
    "barTextColor": "#000",
    "checkboxActiveBackground": "#86c05a",
    "checkboxActiveCircleBackground": "#fff",
    "checkboxDisabledBackground": "#808080",
    "checkboxDisabledCircleBackground": "#fff",
    "checkboxInactiveBackground": "#000",
    "checkboxInactiveCircleBackground": "#fff",
    "controlButtonBackground": "#fff",
    "controlButtonHoverBackground": "#000",
    "controlButtonIconColor": "#000",
    "controlButtonIconHoverColor": "#fff",
    "focusRingColor": "#808080",
    "modalBackground": "#fff",
    "modalButtonBackground": "#f9f9f9",
    "modalButtonColor": "#000",
    "modalButtonHoverBackground": "#eb2b8e",
    "modalButtonHoverColor": "#fff",
    "modalOverlay": "#c7c7c7",
    "modalOverlayOpacity": 0.8,
    "modalTextColor": "#000",
    "modalUnsavedColor": "#fff"
  },
  "cookies": {
    "necessary": [
      {
        "description": "Las cookies necesarias son cruciales para las funciones básicas del sitio web y el sitio web no funcionará de la forma prevista sin ellas. Estas cookies no almacenan ningún dato de identificación personal.",
        "name": "Necesaria"
      }
    ],
    "optional": [
      {
        "id": "optional",
        "name": "Opcionales"
      }
    ]
  },
  "cookieExpiryOffsetMs": 31536000000,
  "cookieNameIsConsentGiven": "ncc_c",
  "cookieNameCookiesEnabledIds": "ncc_e",
  "cookieOptions": {
    "path": "/"
  },
  "isAcceptNecessaryButtonEnabled": false,
  "isControlButtonEnabled": true,
  "isCookieIdVisible": false,
  "isCssEnabled": true,
  "isCssPonyfillEnabled": false,
  "isDashInDescriptionEnabled": true,
  "isIframeBlocked": false,
  "isModalForced": false,
  "locales": [
    "es",
    "en"
  ],
  "localeTexts": {
    "en": {
      "accept": "Accept",
      "acceptAll": "Accept all",
      "bannerDescription": "We use our own cookies and third-party cookies so that we can display this website correctly and better understand how this website is used, with a view to improving the services we offer. A decision on cookie usage permissions can be changed anytime using the cookie button that will appear after a selection has been made on this banner.",
      "bannerTitle": "Cookies",
      "close": "Close",
      "cookiesFunctional": "Functional cookies",
      "cookiesNecessary": "Necessary cookies",
      "cookiesOptional": "Optional cookies",
      "decline": "Decline",
      "declineAll": "Decline all",
      "here": "here",
      "iframeBlocked": "To see this, please enable functional cookies",
      "manageCookies": "Learn more and customize",
      "save": "Save",
      "settingsUnsaved": "You have unsaved settings"
    },
    "es": {
      "accept": "Aceptar",
      "acceptAll": "Aceptar todo",
      "bannerDescription": "Utilizamos cookies propias y de terceros para poder mostrarle una página web y comprender cómo la utiliza, con el fin de mejorar los servicios que ofrecemos. Si continúa navegando, consideramos que acepta su uso.",
      "bannerTitle": "Cookies",
      "close": "Cerrar",
      "cookiesFunctional": "Cookies funcionales",
      "cookiesNecessary": "Cookies obligatorias",
      "cookiesOptional": "Cookies opcionales",
      "decline": "Acepto lo necesario",
      "declineAll": "Borrar todo",
      "here": "aquí",
      "iframeBlocked": "Para ver esto, por favor habilita las cookies funcionales.",
      "manageCookies": "Administrar cookies",
      "save": "Guardar",
      "settingsUnsaved": "Tienes configuraciones no guardadas"
    }
  }
} as ModuleOptions